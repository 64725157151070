import React from 'react'
import { contentCol1, contentCards } from './content'
import CardView from '../../Components/CardView'

function About() {
    return (
        <div className="container-fluid h-100 py-4 about" id="about-section">
            <div className=" container h-100 ">
                <div className="row justify-content-center align-items-center">
                    <div className="col-5">
                        <h4 className=" text-center" >{contentCol1.header}</h4>
                        <p className=" text-center " >{contentCol1.headerDescripton}</p>
                    </div>
                    <div className="col-5">
                        {contentCards.map(contentCard => <CardView key={contentCard.id} content={contentCard} />)}
                    </div>
                </div>

            </div>

        </div>
    )
}

export default About