import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons'
import { faTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons'


function Footer() {
    return (
        <div className='footer py-4'>

            <footer className="bd-footer">
                <div className="text-center border-light ">
                    <div className="card-body py-1">
                        <div className="card-title py-1">
                            Contact Info
                        </div>
                        <div className="row justify-content-center">
                            <div className='  col-md-auto '>
                                <FontAwesomeIcon icon={faEnvelope} /> <a href="mailto:info@mikada.co">info@mikada.co</a>
                            </div>
                            <div className='col-md-auto '>
                                <FontAwesomeIcon icon={faPhone} /> +90 312 911 95 34
                            </div>

                        </div>
                    </div>
                    <div>
                        <p className=" p-0 m-0">
                        </p>
                        <p className=" p-0 m-0">
                            Dumlupınar Bulvarı Next Level No: 3 A D.No: 10 PK: 06520 Çankaya
                        </p>
                        <p className=" p-0 m-0">
                            Ankara / Türkiye
                        </p>
                        <div className="row justify-content-center">
                            <div className='  col-md-auto '>
                                <FontAwesomeIcon icon={faTwitter} />
                            </div>
                            <div className='col-md-auto '>
                                <FontAwesomeIcon icon={faInstagram} />
                            </div>

                        </div>


                    </div>
                </div>
            </footer>
        </div>

    )
}

export default Footer