import React from "react";
import { MainContent } from "./content";
import { Link } from "react-router-dom";
import Products from "../Products/Products";

const Home = () => {
  return (
    <div>
      <div className="container-fluid homeMain align-middle text-center verticalCenter">
        <h1 className="m-auto">{MainContent.header}</h1>
        <h5>{MainContent.headerDescripton}</h5>
        <div className="p-5">
          <a
            type="button"
            className="btn btn-primary"
            href={MainContent.button.link}
          >
            {" "}
            {MainContent.button.title}{" "}
          </a>
        </div>
      </div>
      <hr></hr>
      <Products />
    </div>
  );
};

export default Home;
