import React from 'react'

const CardView = ({ content }) => {
    return (
        <div className=" mb-1 p-0" style={{ borderStyle: "none" }}>
            <div className="row g-0">
                <div className="col-md-1 px-3">
                    <img src={content.image}></img>
                </div>
                <div className="col-md-7">
                    <div className="card-body">
                        <h5 className="card-title">{content.title}</h5>
                        <p className="card-text">{content.body}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CardView