import React from 'react'
import logo from '../../images/logo.png'


const NavBar = () => {



    return (
        <nav className='navbar navbar-expand-lg' >

            <div className="container-fluid">
                <div>
                    <a className="navbar-brand" href="/"> 
                    <img src={logo} height="50"  alt=""></img>
                    </a>

                </div>
                <button className="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarNav"
                    aria-controls="navbarNav"
                    aria-expanded="false"
                    aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarNav">
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">

                    </ul>
                    <div className="d-flex" >
                    <ul className="navbar-nav ">    
                            <li className="nav-item">
                                <a className="nav-link" target="_blank" href="https://mikada.atlassian.net/wiki/spaces/MR/overview">Resources</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link"  target="_blank"  href="https://mikada.atlassian.net/servicedesk/customer/portal/1">Support</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#about-section">About Us</a>
                            </li>
                        </ul>

                    </div>
                </div>
                </div>
        </nav>
    )
}

export default NavBar