export const MainContent =
{
    header: "Use Right App, Save Time",
    headerDescripton: "Excellent business solutions",
    button: {
        title: "Find Solutions",
        link: "#products-section"
    }
}

