import React from "react";
import mondayLogo from "../../images/monday.png";
import pncLogo from "../../images/pnc-image.png";
import { Link } from "react-router-dom";
import aaLogo from "../../images/auto_assing_icon.png"

function Products() {
  return (
    <div className="container text-center" id="products-section">
      <h1> monday.com Apps</h1>
      <br></br>
      <div class="container">
        <div class="row">
          <div class="col-sm">
            <div>
              <img src={pncLogo} alt="private notes and comments app logo" />
              <br></br>
              <br></br>
              <br></br>
              <h5>Private Notes and Comments App for monday.com</h5>
            </div>
          </div>
          <div class="col-sm p-4">
            Mikada provides the app for monday.com.The App was created to keep
            your notes and conversations private from the other members of the
            board.
            <div>
              <br></br>
              
              <a
                type="button"
                className="btn btn-primary"
                href="https://auth.monday.com/oauth2/authorize?client_id=7a3bc27cbafba3161872c4bcf1c2f309&response_type=install"
              >
                Install
              </a>
              <span> </span>

              <Link
                type="button"
                className="btn btn-primary"
                to="/products/private-notes-and-comments"
              >
                {" "}
                Documantation{" "}
              </Link>

            </div>
          </div>
        </div>
    <br></br>
        <div class="row">
          <div class="col-sm">
            <div>
              <img src={aaLogo} alt="auto assign" />
              <br></br>
              <br></br>
              <br></br>
              <h5>Auto Assign App for monday.com</h5>
            </div>
          </div>
          <div class="col-sm p-4">
          With Auto Assign, you can take your boards to the next level by automating the assignment process and streamlining your workflow like never before.
            <div>
              <br></br>
              
              <a
                type="button"
                className="btn btn-primary"
                href="https://auth.monday.com/oauth2/authorize?client_id=3ec2805426dbccf4820885db2f6f87d5&response_type=install"
              >
                Install
              </a>
              <span> </span>

              <a
          className="text-center"
          href="https://mikada.atlassian.net/wiki/spaces/PNC/pages/13959387/How+to+Use+Private+Notes+and+Comments"
        >
      {" "}
                Documantation{" "}        </a>          
            </div>
          </div>
        </div>

      </div>
      <br></br>
      <br></br>
      <br></br>
      <img src={mondayLogo} alt="monday logo" />
    </div>
  );
}

export default Products;
