import "./App.css";
import { Route, Routes } from "react-router-dom";
import Home from "./Pages/Home/Home";
import NavBar from "./Components/NavBar/NavBar";
import About from "./Pages/About/About";
import Products from "./Pages/Products/Products";
import Footer from "./Components/Footer/Footer";
import PNCDoc from "./Pages/PNCDoc/PNCDoc";

function App() {
  return (
    <div className="container">
      <div>
        <div className="navBar">
          <NavBar />
        </div>
      </div>
      <hr className="p-0 m-0"></hr>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route
          path="/products/private-notes-and-comments"
          element={<PNCDoc />}
        />
      </Routes>
      <hr></hr>
      <About />
      <Footer />
    </div>
  );
}

export default App;
