import React from "react";

const PNCDoc = () => {
  return (
    <div className="container">
      <br></br>
      <div className="row align-items-center">
        <div className="col-md-8 offset-md-2 p-2">
          <h2 className="text-center">
            How to use Private Notes and Comments App
          </h2>
          <br></br>
          <h5>
          We encourage you to get a free live demonstration so we can walk you through all the features! <a href="https://calendly.com/mikada-rfq/30min">Get a free demo here</a>
          </h5>
          <br></br>
          <ul>
            <li>Install Private Notes and Comments</li>
            <ol>
              <li>
                Go to monday.com App <b>Marketplace</b>
              </li>
              <li>Search Private Notes and Comments</li>
              <li>
                Click <b>add to my account</b>button on the right-hand side of
                the app window in the Marketplace.
              </li>
            </ol>
            <br></br>
            <li>Private Notes Item View</li>
            <br></br>
            <h6> Step 1: Open the Item Views Center </h6>
            <span>
              {" "}
              Click on the <b>open item page</b> button next to the selected
              item.{" "}
            </span>
            <span>
              {" "}
              Click on the <b>add view</b> button on the right-hand side in the
              opening window.{" "}
            </span>
            <h6> Step 2: Add view to the </h6>
            <span>
              {" "}
              Write Private Notes in the search bar in the opening window.{" "}
            </span>
            <br></br>
            <span> Select the Private Notes item view.</span> <br></br>
            <span>
              {" "}
              Click on the <b>add to item </b> button on{" "}
            </span>
            <h6> Step 3: Write and Save your Private Notes </h6>
            <span>
              {" "}
              Well done! Now, you can write your personal notes and save them
              under the private notes item view.{" "}
            </span>
            <br></br>
            <br></br>
            <li>Private Comments Item View</li>
            <br></br>
            <h6> Step 1: Open the Item Views Center </h6>
            <span>
              {" "}
              Click on the <b>open item page</b> button next to the selected
              item.{" "}
            </span>
            <span>
              {" "}
              Click on the <b>add view</b> button on the right-hand side in the
              opening window.{" "}
            </span>
            <h6> Step 2: Add view to the selected item </h6>
            <span>
              {" "}
              Write Private Comments in the search bar in the opening window.{" "}
            </span>
            <br></br>
            <span> Select the Private Comments item view.</span> <br></br>
            <span>
              {" "}
              Click on the <b>add to item </b> button on{" "}
            </span>
            <h6> Step 3: Write and Save your Private Comments </h6>
            <span>
              {" "}
              Well done! Now, you can write your comment and save them under the
              private comment item view.
              <br></br>
              Your colleagues can see your comments and add their own comments
              under the private comment item view.
            </span>
          </ul>
        </div>
        <a
          className="text-center"
          href="https://mikada.atlassian.net/wiki/spaces/PNC/pages/13959387/How+to+Use+Private+Notes+and+Comments"
        >
          Learn more
        </a>
      </div>
    </div>
  );
};

export default PNCDoc;
