import savetime from "../../images/savetime.png"
import teamsuccess from "../../images/teamsuccess.png"
import verificado from "../../images/verificado.png"

export const contentCol1 =
{
    header: "Founded to make your life easier",
    headerDescripton: "Easy and incredible solutions for your business, feel free to ask us to develop custom Apps. We have an excellent team ready to meet your needs.",

}

export const contentCards = [
    {
        id: 1,
        image: savetime,
        title: "Speed",
        body: "We are working to create apps easy to load and use for increasing the speed."
    },
    {
        id: 2,
        image: verificado,
        title: "Secured Apps",
        body: "We care protection of your application and data"
    },
    {
        id:3,
        image: teamsuccess,
        title: "Success",
        body: "Intelligent and sustainable leading apps to increase your team productivity"
    }
]
